<template>

  <div ref="quitarEnvioEmailModal" class="modal fade modal-forms" data-backdrop="false" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">

    <!-- Mensagem -->
    
    <div class="modal-content" id="confirmacao-parc-envio-email-modal-content" v-if="exibeConfirmacao">
      <div class="modal-header pb-0">
        <h4 class="modal-title">E-mail enviado!</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>        
      <div class="modal-body">
        <p class="text-center">
          Boleto enviado para o e-mail informado.
        </p>
        <!-- <form method="post" id="parc-envio-email-form2" role="form">
          <div class="form-group">
              <div class="input-group">                  
                <div class="input-group-addon"><i class="icon fas fa-mobile-alt"></i></div>
                <input class="form-control input-lg" id="codigo" name="codigo" type="text" placeholder="Código" required="required"  data-parsley-type="alphanum">
              </div>                     
          </div>
          
        </form> -->
        <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" v-on:click="fechar">Fechar</button>
      </div>        
      <!-- <div class="modal-footer">
        <p>Lembrou sua senha? <a href="#" class="abre-modal" v-on:click="showLogin">Acesse seu contrato</a></p>
        
      </div> -->
      
    </div> 

    <!-- FORM -->
    <div class="modal-content" id="confirmacao-parc-envio-email-modal-content" v-if="exibeForm">
      <div class="modal-header pb-0">
        <h4 class="modal-title">Envio de boleto de quitação por e-mail</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>        
      <div class="modal-body">
        <p class="text-center">Informe seu e-mail para recebimento do boleto de quitação.</p>
         
        <form method="post" id="parc-envio-email-form" role="form">
          <p class="alert alert-warning p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon"><i class="icon fas fa-at"></i></div>
              <input
                type="text"
                class="form-control input-lg"
                name="email"
                placeholder="E-mail"
                v-model="email"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon"><i class="icon fas fa-at"></i></div>
              <input
                type="text"
                class="form-control input-lg"
                name="emailConfirmacao"
                placeholder="Confirme o e-mail"
                v-model="emailConfirmacao"
              />
            </div>
          </div>
          <!-- <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" href="forms/esqueci-senha-form2.html" data-remote="false" data-target="#parcela-envio-email-modal">Enviar SMS</button> -->
          <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              data-remote="false"
              data-target="#parcela-envio-email-modal"
              v-on:click="enviar"
            >
              <div class="spinner-border" role="status" v-if="exibeLoading">
                <span class="sr-only"></span>
              </div>
              Enviar
          </button>
        </form>
      </div>        
      <!-- <div class="modal-footer">
        <p>Lembrou sua senha? <a href="forms/login-form.html" data-remote="false" data-dismiss="modal" data-toggle="modal" data-target="#login-modal" class="abre-modal">Acesse seu contrato</a></p>
      </div> -->
      
    </div>      

    </div>
  </div>

  
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import EventBus from '../event-bus';
import moment from "moment";

export default {
  name: "QuitacaoEnvioEmail",
  props: {
    contrato: {}
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      errors: [],
      processing: false,
      solicitacaoRealizada: false,
      email: "",
      emailConfirmacao: ""
    };
  },
  mounted() {
    var self = this;
    EventBus.$on('eventShowParcelaEnvioEmail', function() {
      self.show();
    });
  },
  computed: {
    exibeLoading:function() {
      return this.processing;
    },
    exibeForm: function() {
      return !this.solicitacaoRealizada;
    },
    exibeConfirmacao: function() {
      return  this.solicitacaoRealizada;
    }
  },
  methods: {
    fechar: function(event) {
      if (event) {
        event.preventDefault();
      }
      `// console.log($(this.$refs.quitarEnvioEmailModal));`
      $(this.$refs.quitarEnvioEmailModal).modal("hide");
      // $("#parcela-envio-email-modal").modal("hide");
    },
    show: function(event){
      if (event) {
        event.preventDefault();
      }
      this.errors = [];
      this.emailConfirmacao = '';
      this.email = '';
      this.solicitacaoRealizada = false;
      // console.log($(this.$refs.quitarEnvioEmailModal));
      $(this.$refs.quitarEnvioEmailModal).modal("show");
      // $("#parcela-envio-email-modal").modal("show");
    },
    enviar: function(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      this.errors = [];

      if (this.email.length == 0) {
        this.errors.push("Informe o seu E-mail.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Informe um e-mail válido");
      } else if (this.email != this.emailConfirmacao) {
        this.errors.push("Confirmação do e-mail não confere.");
      }

      if (this.errors.length == 0) {

        let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
        defaultClient.timeout = 60000 * 2;
        defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
        let userData = this.authService.userData();

        let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

        let empresa = userData.Empresa;
        let agencia = this.contrato.agencia;
        let contrato = this.contrato.numeroContrato; // String |
        let dataPagamento = moment(this.vencimento).format("YYYY-MM-DD");
        let situacao = "A"; // String |
        this.contratoEmAtraso = false;
        var self = this;

        apiContrato.consultaQuitacaoContratoSimulacao(empresa, agencia, contrato, dataPagamento, situacao, (error, data, response) => {
          if (!error) {
            apiContrato.emailBoleto(empresa, data.simulacao, self.email, (error, data, response) => {
              self.processing = false;
              self.buttonPressed = 0;

              if (!error) {
                this.solicitacaoRealizada = true;
              } else {
                if (response && response.text) {
                  try {
                    let listOfErrors = JSON.parse(response.text);
                    listOfErrors.forEach(error => {
                      this.errors.push(error.message);
                    });
                  } catch (e) {
                    this.errors.push("Erro ao enviar boleto por e-mail. Tente novamente.");
                  }
                } else {
                  this.errors.push("Erro ao enviar boleto por e-mail. Tente novamente.");
                }
              }
              
            });
          } else {
            this.parserException(response, 'Erro ao baixar boleto.');
          }
        });
      }
    },
    validEmail: function(value) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    }
  }
};
</script>

<style scoped>
/* .modal {
  z-index: 90000;
} */
</style>