<template>
  <!-- Main Content -->
  <div id="content" class="mb-5">
        <!-- Begin Page Content -->
        <div id="quitarContratos" class="container-fluid">

          <div class="d-sm-flex align-items-center justify-content-between my-4 pt-2 mx-md-4 ">
            <h1 class="h3 mb-0 text-center">Quitar Contrato</h1>
          </div>
          <!-- <div class="d-flex align-items-center mb-2 mx-md-4 pb-3 fs-sm-down-09x">
            <p class="mb-0">Vencimento 10/03/2019</p>
            <div class="ml-4"><a href="#"><i class="far fa-calendar-alt"></i> Trocar data</a></div>
          </div> -->
          <div v-if="semContratos" class="text-center">
            Você não possui contratos ativos.
          </div> 
          <div v-if="carregando" class="loader text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            Carregando contratos ... 
          </div>
          <p class="alert alert-warning p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
                <div class="row row-list-cards" v-if="carregado">
                  <div class="item" v-for="(contrato, index) in contratos" v-bind:key="index" >
                    <div class="card shadow mb-4">
                      <div class="card-body card-modal-default">
                        <QuitarTab :contrato="contrato"></QuitarTab>
                        <QuitarTabContent :contrato="contrato"></QuitarTabContent>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- <div class="dropdown contratos changeOnSelect menu mt-2 mb-4">
                  <button class="btn btn-primary btn-sm dropdown-toggle mx-auto ml-md-0 d-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Alterar contrato
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  </div>
                </div> -->

                <!-- <div id="contratos-tabs" class="col-xl-4 pr-xl-0 px-0 px-lg-2">
                  <div class="nav flex-column" role="tablist" aria-orientation="vertical">
                    <a class="contrato-tab" 
                      data-toggle="pill" 
                      role="tab" 
                      :class="{ 'active': index === 0 }"
                      :id="`contrato-${ contrato.numeroContrato }-tab`"
                      :href="`#contrato-${ contrato.numeroContrato }`"
                      :aria-controls="`contrato-${ contrato.numeroContrato }`"
                      :aria-selected="`${ index === 0 ? 'true' : 'false'}`"
                      v-for="(contrato, index) in contratos" v-bind:key="index"

                    >
                      <QuitarTab :contrato="contrato"></QuitarTab>
                    </a>
                  </div>
                </div> -->

<!-- 
                <div id="contrato-content" class="col-xl-8 px-0 pl-xl-0">
                  <div class="card h-100">
                    <div class="tab-content card-body" id="contratos-tabContent">
                      <div 
                        class="responsive-table tab-pane" 
                        role="tabpanel" 
                        :class="{ 'active': index === 0, 'show': index === 0 }"
                        :id="`contrato-${ contrato.numeroContrato }`"
                        :aria-labelledby="`contrato-${ contrato.numeroContrato }-tab`"
                        v-for="(contrato, index) in contratos" v-bind:key="index"
                      > 
                        <QuitarTabContent :contrato="contrato"></QuitarTabContent>
                      </div>
                    </div>
                  </div>
                </div> -->

            </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->
</template>

<script>

import QuitarTab from '../components/QuitarTab'
import QuitarTabContent from "../components/QuitarTabContent";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";

export default {
  name: "QuitarContrato",
  components:{
    QuitarTab,
    QuitarTabContent
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      contratos:[],
      errorMessage:'',
      primeiraConsulta: false,
      errors: []
    }
  },
  computed: {
    exibeErrorMessage: function() {
      return this.errorMessage != '';
    },
    carregando: function() {
      return !this.primeiraConsulta;
    },
    carregado: function() {
      return this.primeiraConsulta && this.errors.length == 0 && (this.contratos && this.contratos.length > 0);
    },
    semContratos: function() {
      return this.errors.length === 0 && this.contratos.length == 0 && this.primeiraConsulta;
    }
  },
  mounted() {
    this.$nextTick(() => {
    let config = this.authService.config();
    let contratosApi = new ApiPortalAutoAtendimento.ContratoApi();
    let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
    defaultClient.basePath = config.endpoints.autoatendimento;
    defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
    let userData = this.authService.userData();

    let empresa = userData.Empresa; 
    let cpfcliente = userData.CpfCnpj;
    let situacao = "A"; 

    if (cpfcliente == "" || this.authService.isFastAccessCompleto()){
        cpfcliente = localStorage.cpfCnpj;
    }

    contratosApi.consultaContratosPorCliente(empresa, cpfcliente, situacao, (error, data, response) => {
      this.primeiraConsulta = true;
      if (!error) {
        this.contratos = data;
      } else {
        if (response && response.text) {
          try {
            let listOfErrors = JSON.parse(response.text);
            listOfErrors.forEach(error => {
              this.errors.push(error.message);
            });
          } catch (e) {
            this.errors.push("Erro ao obter dados do contrato.");
          }
        } else {
          this.errors.push("Erro ao obter dados do contrato.");
        }
      }
    });
    });
  }
}
</script>

<style scoped>
#content {
  height: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>