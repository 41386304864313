<template>
  <div v-if="!mostraContrato">
    <p :id="`mensagem-${ contrato.numeroContrato }`">O contrato {{ contrato.numeroContrato }} do cliente {{ cpfCnpj }} está vinculado a outro lojista.</p>
  </div>
  <div v-else>
  <!-- principal de iten do contrato -->
    <div class="card-content" data-toggle="modal" :data-target="`#details-item-${ contrato.numeroContrato }`">
      <div class="col1">
        <div class="title">
          <small>Financiamento</small>
          <h2 class>{{ contrato.descricao }}</h2>
        </div>
        <div class="number">
          <small>Número do Contrato</small>
          <p>{{ contrato.numeroContrato }}</p>
        </div>
      </div>
      <div class="col2">
        <div class="chart-default" style="width: 140px;height: 140px;">
          <canvas :id="`grafico-financiamento-${ contrato.numeroContrato }`"></canvas>
        </div>
        <div class="text-center lh-1">
          <small>
            <strong>{{ ((100 * contrato.qtdParcelasPagas)/contrato.qtdParcelas).toFixed(0) }}% <br></strong>
            <span>Parcelas pagas: <br></span>
            <strong>{{ contrato.qtdParcelasPagas}}/{{ contrato.qtdParcelas }}</strong>
          </small>
        </div>
      </div>
    </div>

    <div class="card-bottom-button">
      <div class="text-center">
        <button data-toggle="modal" :data-target="`#details-item-${ contrato.numeroContrato }`" type="button" class="btn btn-link btn-link-gray">Ver Detalhes</button>
      </div>
    </div>
  <!-- fim principal de iten do contrato -->
</div>
</template>
<script>
// import Contratos from "../api_portal_auto_atendimento/src/model/Contratos";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import Chart from 'chart.js';

export default {
  name: 'QuitarTab',
  props: {
    contrato: Object
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      cpfCnpj: "",
      mostraContrato: false
    }
  },
  mounted() {
    let userData = this.authService.userData();
    this.cpfCnpj = localStorage.cpfCnpj;
    if (this.authService.isFastAccessCompleto())
      {
        this.cpfCnpj = localStorage.cpfCnpj;
        if (!userData)
        {
          userData = JSON.parse(localStorage.userData);
        }
        this.mostraContrato = userData.LojistasLojas[0].Item1 == this.contrato.lojista;
      }
      else
      {
        this.mostraContrato = true;
      }
    this.$nextTick(() => {
      

      if (this.mostraContrato)
      {
        (Chart.defaults.global.defaultFontFamily = "Nunito"),
        //'-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
        Chart.defaults.global.defaultFontColor = "#333333";

        // Grafico Financiamento 01
        var ctx = document.getElementById("grafico-financiamento-" + this.contrato.numeroContrato);
        
        var myPieChart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: ["Pago", "Em aberto"],
                datasets: [{
                    data: [this.contrato.qtdParcelasPagas, this.contrato.qtdParcelas - this.contrato.qtdParcelasPagas],
                    backgroundColor: ['#030065', '#F10707'],
                    hoverBackgroundColor: ['#0700de', '#ff4c4c'],
                    hoverBorderColor: "rgba(234, 236, 244, 1)",
                }]
            },
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    backgroundColor: "rgb(255,255,255)",
                    bodyFontColor: "#333333",
                    borderColor: "#dddfeb",
                    borderWidth: 1,
                    xPadding: 10,
                    yPadding: 10,
                    displayColors: false,
                    caretPadding: 10
                },
                legend: {
                    display: false
                },
                cutoutPercentage: 80
            }
        });
      }
    });
  }
}

</script>
